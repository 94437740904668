// import moment from "moment";
// import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
// import openCloudinaryWidget from "utils/cloudinary";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { defined } from "pages/orders/data";
import { StyledArtfiles, NoArtfileFound } from "styles/pages/order";

const Artfiles = ({ state, setState, updateArtfiles }) => {
  // const user = useSelector((store) => store?.user);
  const isOrderDefined = state?.status === defined.value;
  const artfiles = state?.artfiles;
  const showAddAndDeleteButton = !state?.status || isOrderDefined;

  return (
    <StyledArtfiles>
      <div className="header">
        <div>Artfiles</div>
        {/* {showAddAndDeleteButton && (
          <AddCircleIcon
            onClick={() => {
              openCloudinaryWidget(`ConfluenceApp/Artfiles`, [], (v) => {
                const data = {
                  secureUrl: v?.secure_url,
                  email: user?.email,
                  timestamp: moment().unix(),
                  fileName: v?.secure_url?.split("/Artfiles/")[1],
                };

                const newArtFiles = artfiles?.length
                  ? [...artfiles, data]
                  : [data];

                setState({
                  ...state,
                  artfiles: newArtFiles,
                });

                if (state?.orderNumber && state?.status) {
                  updateArtfiles(newArtFiles);
                }
              });
            }}
          />
        )} */}
      </div>

      {artfiles?.length && (
        <Stack direction="row" spacing={1} sx={{ marginTop: "10px" }}>
          {artfiles?.map((a) => (
            <Chip
              key={a?.timestamp}
              icon={<FilePresentIcon />}
              label={a?.fileName}
              variant="outlined"
              onClick={() => window.open(a?.secureUrl)}
              onDelete={
                showAddAndDeleteButton
                  ? () => {
                      const newArtfiles = artfiles?.filter(
                        (b) => a?.fileName !== b?.fileName
                      );

                      setState({
                        ...state,
                        artfiles: newArtfiles,
                      });

                      if (state?.orderNumber && state?.status) {
                        updateArtfiles(newArtfiles);
                      }
                    }
                  : null
              }
            />
          ))}
        </Stack>
      )}

      {!artfiles && (
        <NoArtfileFound severity="error">
          No Artfiles are Uploaded !
        </NoArtfileFound>
      )}
    </StyledArtfiles>
  );
};

Artfiles.propTypes = PROP_TYPES;

Artfiles.defaultProps = DEFAULT_STATE;

export default Artfiles;
