import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Container from "styles/components/textfield-input";

const TextFieldInput = ({ label, value, handleChange, ...rest }) => (
  <Container>
    <TextField
      label={label}
      value={value}
      onChange={handleChange}
      size="small"
      {...rest}
    />
  </Container>
);

TextFieldInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
};

TextFieldInput.defaultProps = {
  label: "",
  value: "",
  handleChange: () => {},
};

export default TextFieldInput;
