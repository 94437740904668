import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TextField from "components/TextFieldInput";
import PasswordInput from "components/PasswordInput";
import Snackbar from "components/SnackBar";
import LoadingButton from "components/LoadingButton";
import ForgotPasswordDialog from "pages/login/ForgotPasswordDialog";
import { usePost } from "hooks/usePost";
import { setUser } from "features/user/userSlice";
import { CRYPTO_KEY, LOGIN } from "constants/services";
import { ORDERS } from "constants/routes";
import Logo from "assets/images/logo.png";
import {
  Wrapper,
  StyledForm,
  Footer,
  StyledLogo,
  Title,
  SubTitle,
  ForgotPassword,
} from "styles/pages/login";

const Form = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [forgotPassword, setForgotPassword] = useState(false);

  return (
    <Wrapper>
      <StyledLogo alt="logo" src={Logo} />
      <Title>Sign in</Title>
      <SubTitle>to continue to Customer TruTrac</SubTitle>
      <StyledForm>
        <TextField
          label="User"
          fullWidth
          value={email}
          handleChange={(e) => setEmail(e?.target?.value)}
        />

        <PasswordInput label="PIN" value={password} setValue={setPassword} />

        <ForgotPassword onClick={() => setForgotPassword(true)}>
          I do not know the password
        </ForgotPassword>

        <Footer>
          <LoadingButton
            size="large"
            disabled={!email || !password}
            onClick={async () => {
              const payload = {
                email,
                pin: CryptoJS.AES.encrypt(password, CRYPTO_KEY).toString(),
              };

              const { data } = await mutateAsync({
                url: LOGIN,
                data: payload,
              });

              if (data?.authToken) {
                dispatch(setUser(data));
                navigate(ORDERS, { replace: true });
              } else {
                setError({ message: data?.message, severity: "error" });
              }
            }}
            label="Login"
            variant="contained"
          />
        </Footer>
      </StyledForm>

      {forgotPassword && (
        <ForgotPasswordDialog
          open={forgotPassword}
          setOpen={setForgotPassword}
          passwordChanged={(v) => {
            setForgotPassword(false);
            setError({
              message: v?.message,
              severity: v?.success ? "success" : "error",
            });
          }}
        />
      )}

      <Snackbar
        message={error?.message}
        onClose={() => setError(null)}
        severity={error?.severity}
      />
    </Wrapper>
  );
};

export default Form;
