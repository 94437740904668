import Typography from "@mui/material/Typography";
import LogoSm from "assets/images/logo-sm.jpeg";
import { StyledSmLogo, StyledTitleWrapper } from "styles/components/header";

const Title = () => (
  <StyledTitleWrapper>
    <StyledSmLogo alt="logo" src={LogoSm} />
    <Typography variant="h6">TruTrac (Customer)</Typography>
  </StyledTitleWrapper>
);

export default Title;
