import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PageTitle from "components/PageTitle";
import CreateOrderRequestDialog from "pages/order-request/CreateOrderRequestDialog";
import Pagination from "components/Pagination";
import OrderRequestsTable from "pages/order-request/OrderRequestsTable";
import OrderRequestRepliesDialog from "pages/order-request/OrderRequestRepliesDialog";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_CUSTOMER_REQUEST } from "constants/services";
import { DEFAULT_STATE, PROP_TYPES } from "pages/order-request/prop-types";
import { StyledContainer, NoDataFound } from "styles/pages/order-request";

const take = 10;

const OrderRquestPage = () => {
  const user = useSelector((s) => s?.user);
  const { mutateAsync } = usePost();
  const [state, setState] = useState({ page: 1 });
  const orderRequests = state?.orderRequests;

  const fetchOrderRequests = async () => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_CUSTOMER_REQUEST,
      data: {
        vendor: user?.vendorName,
        take,
        skip: (state.page - 1) * take,
      },
    });
    setState({
      ...state,
      orderRequests: data,
      showCreateQuoteRequestDialog: false,
    });
  };

  useEffect(() => {
    if (!orderRequests) fetchOrderRequests();
  }, [orderRequests, state?.page]);

  return (
    <StyledContainer>
      <PageTitle
        title="Order Quote Requests"
        onCreateOrderQuoteRequest={() =>
          setState({ ...state, showCreateQuoteRequestDialog: true })
        }
      />

      {!orderRequests?.data?.length && (
        <NoDataFound>No data found !</NoDataFound>
      )}

      {orderRequests?.data?.length > 0 && (
        <Pagination
          count={Math.ceil(state.orderRequests.totalCount / take)}
          page={state?.page}
          handleChange={(e, v) => setState({ ...state, page: v })}
        />
      )}

      {orderRequests?.data?.length > 0 && (
        <OrderRequestsTable state={state} setState={setState} />
      )}

      {state?.showCreateQuoteRequestDialog && (
        <CreateOrderRequestDialog
          state={state}
          setState={setState}
          fetchOrderRequests={() => {
            if (!orderRequests) {
              fetchOrderRequests();
            } else {
              setState({
                ...state,
                orderRequests: null,
              });
            }
          }}
        />
      )}

      {state?.selectedRequest && (
        <OrderRequestRepliesDialog state={state} setState={setState} />
      )}
    </StyledContainer>
  );
};

OrderRquestPage.propTypes = PROP_TYPES;

OrderRquestPage.defaultProps = DEFAULT_STATE;

export default OrderRquestPage;
