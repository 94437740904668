import styled from "styled-components";
import Container from "@mui/material/Container";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import FilePresentIcon from "@mui/icons-material/FilePresent";

export const StyledContainer = styled(Container)``;

export const CreateOrderRequestBody = styled.div`
  margin: 6px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Attachment = styled.div`
  h1.title {
    margin: 0;
    font-size: 14px;
  }
  div.body {
    border: 2px dashed #ccc;
    min-height: 100px;
    margin-top: 7px;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  h3.attachment-files {
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
  h3.browse {
    margin: 0;
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
    span {
      cursor: pointer;
    }
  }

  div.attachments {
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .files {
    cursor: pointer;
    text-align: center;
    h5 {
      margin: 0;
    }
  }
`;

export const NoDataFound = styled.h3`
  text-align: center;
  color: #a1a1a1;
`;

export const OrderRequestRepliesHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  .title {
    display: flex;
    flex: 1;
    margin-left: 10px;
    flex-direction: column;
    h1 {
      margin: 0;
      font-size: 16px;
    }

    h3 {
      margin: 0;
      font-size: 14px;
      color: #a1a1a1;
    }
  }
`;

export const StyledBugReportIcon = styled(ConfirmationNumberIcon)`
  &.MuiSvgIcon-root {
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 5px;
    color: #a1a1a1;
  }
`;

export const StyledReplyWrapper = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  .public-DraftEditor-content {
    min-height: 100px;
  }
  .profile-pic {
    border: 1px solid #ccc;
    padding: 5px;
    width: 52px;
    height: 52px;

    img {
      object-fit: contain;
    }
  }

  .editor {
    button.submit {
      margin-top: 10px;
      float: right;
      font-size: 12px;
    }
  }
`;

export const StyledReplies = styled.div`
  margin-top: 20px;

  .reply {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }

  h2.title {
    margin: 0;
    font-size: 14px;

    .created-date {
      color: #a1a1a1;
      font-size: 12px;
      padding-left: 5px;
      float: right;
    }
  }

  .message {
    padding: 10px 0;
  }

  .attachments {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;

    .attachment {
      cursor: pointer;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      border-radius: 3px;
      h5 {
        margin: 4px 0 0;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
  }
`;

export const StyledFilePresentIcon = styled(FilePresentIcon)``;
