import styled from "styled-components";
import { Link } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import AppBar from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import SettingsIcon from "@mui/icons-material/Settings";

export const Container = styled(AppBar)`
  &.MuiPaper-root {
    background-color: #000;
  }
`;

export const StyledAppsIcon = styled(AppsIcon)`
  &.MuiSvgIcon-root {
    color: #fff;
    font-size: 36px;
    cursor: pointer;
  }
`;

export const ProfileImage = styled.img`
  width: 40px;
  border-radius: 50%;
  height: 40px;
`;

export const DrawerLogo = styled(ListItem)`
  &.MuiListItem-root {
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
`;

export const StyledSmLogo = styled.img`
  width: 42px;
  margin-right: 10px;
  margin-left: 40px;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const StyledSettingsIcon = styled(SettingsIcon)`
  &.MuiSvgIcon-root {
    font-size: 30px;
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

export const UserInfo = styled.div`
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 20px 10px;
  right: 0;
  left: 0;
  text-align: center;

  .name {
    font-weight: bold;
  }

  .role-name {
    color: #a1a1a1;
  }
`;
