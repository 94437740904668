export const defined = { title: "Defined", value: "Defined" };
export const completed = { title: "Completed", value: "Completed" };
export const shipped = { title: "Shipped", value: "Shipped" };
export const inProduction = { title: "In-Production", value: "In-production" };
export const ready = { title: "Pre-Prod", value: "Ready" };
export const cancelled = { title: "Cancelled", value: "Cancelled" };

export const TABS_TITLES = [
  defined,
  ready,
  inProduction,
  completed,
  shipped,
  cancelled,
];

export const ORDER_STATUS = [
  defined.value,
  ready.value,
  completed.value,
  shipped.value,
  inProduction.value,
  cancelled.value,
];

export const QUICK_FILTER_OPTIONS = [
  { id: "null", label: "Artproof Pending" },
  { id: "pending", label: "Submission Pending" },
  { id: "reviewing", label: "Under Review" },
  { id: "approved", label: "Artproof Approved" },
  { id: "declined", label: "Artproof Declined" },
];
