// export const BASE_URL = "https://c7b8-2607-fea8-999e-b940-fcf3-d219-68d5-a5f0.ngrok.io/v1";
export const BASE_URL = "https://confluence-bot-app.herokuapp.com/v1";
export const CRYPTO_KEY = "122BOTConfluence";
export const CONFLUENCES_USERS = "/confluence-users";
export const CONFLUENCES_USER = "/confluence-customer";
export const LOGIN = "/confluence-customer/sign-in";
export const CONFLUENCE_USER_EVENTS = "/confluence-customer/events";
export const CONFLUENCE_VENDORS = "/confluence-vendors";
export const CONFLUENCE_ORDERS = "/confluence-orders";
export const CONFLUENCE_VENDORS_CLIENTS = `${CONFLUENCE_VENDORS}/clients`;
export const CONFLUENCE_NOTIFICATIONS = "/confluence-notification";
export const DECORATIONS = "/confluence-decorations";
export const PRINT_PROVIDERS = "/confluence-print-providers";
export const COMMENTS = "/confluence-orders-comments";
export const APP_VERSION = "/update-app-version";
export const CONFLUENCE_PURCHASE_ORDER = "/confluence-purchase-order";
export const CONFLUENCE_SCAN_PURCHASE_ORDER = "/scan-purchase-order";
export const CONFLUENCE_OTHER_CHARGES = "/confluence-other-charges";
export const QUICK_BOOKS_PDF_GENERATION = "/quickBooks-estimate-pdf";
export const QUICK_BOOKS_CUSTOMER = "/quickBooks-customers";
export const QUICK_BOOKS_ITEMS = "/quickBooks-items";
export const QUICK_BOOKS_SALES_CODE = "/quickBooks-sales-codes";
export const QUICK_BOOKS_ESTIMATE = "/quickBooks-estimates";
export const QUICK_BOOKS_REFRESH_TOKEN = "/qbRefresh";
export const QUICK_BOOKS_CA_AUTH_REQUEST = "/qbCAAuthRequest";
export const QUICK_BOOKS_US_AUTH_REQUEST = "/qbUSAuthRequest";
export const CONFLUENCE_CUSTOMER_PASSWORD = "confluence-customer/password";
export const CONFLUENCE_CUSTOMER_REQUEST = "/confluence-customer/request";
export const CONFLUENCE_CUSTOMER_REQUEST_REPLY =
  "/confluence-customer/request-reply";
