import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_CUSTOMER_PASSWORD } from "constants/services";

const ForgotPasswordDialog = ({ open, setOpen, passwordChanged }) => {
  const [email, setEmail] = useState("");
  const handleClose = () => setOpen(false);
  const { mutateAsync } = usePost();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Recover Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Password will be sent out to provided email address, if this email
          address is present in TruTrac database. <br />
          <br /> If you do not receive email, please check your spam or junk
          folders.
        </DialogContentText>
        <TextField
          sx={{ marginTop: "30px", marginBottom: "20px" }}
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e?.target?.value)}
        />
        <p style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
          Note: Please contact&nbsp;
          <a href="mailto:customerservice@bigoventees.com">
            customerservice@bigoventees.com
          </a>
          &nbsp;if you face any issue.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!email}
          onClick={async () => {
            const payload = { email };

            const { data } = await mutateAsync({
              url: CONFLUENCE_CUSTOMER_PASSWORD,
              data: payload,
            });
            passwordChanged(data);
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  passwordChanged: PropTypes.func,
};

ForgotPasswordDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  passwordChanged: () => {},
};

export default ForgotPasswordDialog;
