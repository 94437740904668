import { useMutation } from "react-query";
import HttpService from "services/http";

export const post = async ({ url, data, isPut = false, isPatch = false }) => {
  try {
    let response;

    if (isPut) {
      response = await HttpService.put(url, data);
    } else if (isPatch) {
      response = await HttpService.patch(url, data);
    } else {
      response = await HttpService.post(url, data);
    }

    return response;
  } catch (error) {
    return { error: error?.message };
  }
};

export const usePost = () => useMutation(post);
