import { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DialogTitle from "@mui/material/DialogTitle";
import RichTextEditor, {
  initiateEditorState,
  getHtml,
} from "components/RichTextEditor";
import { usePost } from "hooks/usePost";
import openCloudinaryWidget from "utils/cloudinary";
import { CONFLUENCE_CUSTOMER_REQUEST_REPLY } from "constants/services";
import { DEFAULT_STATE, PROP_TYPES } from "pages/order-request/prop-types";
import {
  OrderRequestRepliesHeader,
  StyledBugReportIcon,
  StyledReplyWrapper,
  StyledReplies,
  Attachment,
  StyledFilePresentIcon,
} from "styles/pages/order-request";

const OrderRequestRepliesDialog = ({ state, setState }) => {
  const { mutateAsync } = usePost();
  const [reply, setReply] = useState(initiateEditorState());
  const [attachments, setAttachments] = useState([]);
  const [replies, setReplies] = useState([]);
  const user = useSelector((s) => s?.user);
  const data = state?.selectedRequest;
  const open = !!data;

  const handleClose = () => setState({ ...state, selectedRequest: undefined });

  const fetchReplies = async () => {
    const { data: response } = await mutateAsync({
      url: CONFLUENCE_CUSTOMER_REQUEST_REPLY,
      data: { requestId: data?.requestId },
    });

    setReplies([...response, { ...data, profileImg: user?.profileImg }]);
  };

  useEffect(() => {
    fetchReplies();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <OrderRequestRepliesHeader>
          <StyledBugReportIcon />
          <div className="title">
            <h1>{data?.subject}</h1>
            <h3>
              {moment.unix(data?.createdDate).format("MMM DD, YYYY")}
              &nbsp;&#x2022;&nbsp;
              {data?.email}
            </h3>
          </div>
        </OrderRequestRepliesHeader>
      </DialogTitle>
      <DialogContent>
        <StyledReplyWrapper>
          <Avatar
            alt="profile-pic"
            className="profile-pic"
            src={user?.profileImg}
          />

          <div className="editor">
            <RichTextEditor
              value={reply}
              onChange={(v) => setReply(v)}
              style={{ height: "500px" }}
              title="Reply"
            />

            <Attachment>
              <div className="body">
                {attachments?.length > 0 && (
                  <div className="attachments">
                    {attachments?.map((a, i) => (
                      <div
                        key={a}
                        aria-hidden="true"
                        className="files"
                        onClick={() => window.open(a)}
                      >
                        <FilePresentIcon fontSize="large" />
                        <h5>File {i + 1}</h5>
                      </div>
                    ))}
                  </div>
                )}

                {(!attachments || attachments?.length === 0) && (
                  <h3 className="attachment-files">Attachment files</h3>
                )}
                <h3 className="browse">
                  <span
                    aria-hidden="true"
                    onClick={() => {
                      openCloudinaryWidget(
                        `ConfluenceApp/OrderQuoteRequests/${data?.requestId}/Attachments`,
                        [],
                        (v) =>
                          setAttachments(
                            !attachments || attachments?.length === 0
                              ? [v?.secure_url]
                              : [...attachments, v?.secure_url]
                          )
                      );
                    }}
                  >
                    browse
                  </span>
                </h3>
              </div>
            </Attachment>
            <Button
              disabled={!reply.getCurrentContent().hasText()}
              variant="contained"
              className="submit"
              onClick={async () => {
                const { data: response } = await mutateAsync({
                  url: CONFLUENCE_CUSTOMER_REQUEST_REPLY,
                  data: {
                    vendor: data?.vendorName,
                    requestId: data?.requestId,
                    urgency: data?.urgency,
                    subject: data?.subject,
                    body: getHtml(reply),
                    vendorUser: data?.email,
                    repliedBy: user?.email,
                    status: "OPEN",
                    attachments,
                  },
                  isPut: true,
                });

                if (response?.success) {
                  fetchReplies();
                  setAttachments([]);
                  setReply(initiateEditorState());
                } else {
                  alert(response?.message);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </StyledReplyWrapper>

        <StyledReplies>
          {replies?.map((r, i) => {
            const key = `${r?.requestId}_${i}`;
            return (
              <div className="reply" key={key}>
                <Avatar src={r?.profileImg} sx={{ width: 24, height: 24 }} />
                <div style={{ width: "100%" }}>
                  <h2 className="title">
                    <span>{r?.email || r?.repliedBy}</span>

                    <span className="created-date">
                      {moment
                        .unix(r?.createdDate)
                        .format("MMM DD, YYYY hh:mm A")}
                    </span>
                  </h2>
                  <div
                    className="message"
                    dangerouslySetInnerHTML={{ __html: r?.body || r?.message }}
                  />
                  <div className="attachments">
                    {r?.attachments?.map((a, j) => (
                      <div
                        className="attachment"
                        key={a}
                        onClick={() => window.open(a)}
                        aria-hidden="true"
                      >
                        <StyledFilePresentIcon />
                        <h5>File {j + 1}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </StyledReplies>
      </DialogContent>
    </Dialog>
  );
};

OrderRequestRepliesDialog.propTypes = PROP_TYPES;

OrderRequestRepliesDialog.defaultProps = DEFAULT_STATE;

export default OrderRequestRepliesDialog;
