import styled from "styled-components";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const Container = styled.div`
  border-bottom: 1px solid #000;
  padding-bottom: 2px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)``;

export const Icons = styled.div``;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  cursor: pointer;
`;
