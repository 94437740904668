import PropTypes from "prop-types";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import PageTitle from "components/PageTitle";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import Button from "components/LoadingButton";
import openCloudinaryWidget from "utils/cloudinary";
import { defined } from "pages/orders/data";
import {
  OrderInfoContainer,
  // UploadPO,
  ArtProofStatus,
  ArtProofTitle,
  ArtproofActions,
  EditArtProofButton,
  ArtProofIframe,
  Notes,
} from "styles/pages/order";

const OrderInformation = ({
  state,
  updateOrder,
  sendNotification,
  onApprove,
}) => {
  const openCloudinary = () =>
    openCloudinaryWidget(`ConfluenceApp/ArtProofs`, ["pdf"], (v) =>
      updateOrder(v?.secure_url)
    );

  const artProof = state?.artProof;
  const artproofDecision = artProof?.decision;
  const artProofNotes = artProof?.notes;
  const isArtProofDecisionPending = artproofDecision === "pending";
  const isArtProofDecisionDeclined = artproofDecision === "declined";
  const isArtProofDecisionApproved = artproofDecision === "approved";
  const isOrderDefined = state?.status === defined?.value;

  return (
    <OrderInfoContainer>
      <PageTitle title="Order Information" />

      <div className="row">
        <div className="col">
          <h3>
            <ArtProofTitle>Submitted by:</ArtProofTitle>
            <span>{state?.clientEmails?.map((c) => c?.value)?.join(",")}</span>
          </h3>
          <iframe src={state?.poSecureUrl} title="po-secure-url" />
        </div>
        <div className="col">
          {state?.artProof ? (
            <>
              <div className="header">
                <h3>
                  <ArtProofTitle>Status:</ArtProofTitle>
                  <ArtProofStatus artProof={state?.artProof}>
                    {artproofDecision}
                  </ArtProofStatus>
                </h3>
                <ArtproofActions>
                  {(isArtProofDecisionPending || isArtProofDecisionDeclined) &&
                    !state?.preApprovedArtproofs && (
                      <Button
                        startIcon={<SendIcon />}
                        label="Approval"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          sendNotification({
                            notificationType: "Artproof_Review",
                            orderNumber: state?.poNumber,
                            vendorName: state?.vendor?.label,
                          });
                        }}
                      />
                    )}

                  {(isArtProofDecisionPending ||
                    isArtProofDecisionDeclined ||
                    isOrderDefined) &&
                    state?.preApprovedArtproofs &&
                    !isArtProofDecisionApproved && (
                      <Button
                        startIcon={<DoneIcon />}
                        label="Approve"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          onApprove({
                            artProof: {
                              ...state?.artProof,
                              decision: "approved",
                            },
                            orderNumber: state?.poNumber,
                            vendorName: state?.vendor?.label,
                          });
                        }}
                      />
                    )}
                  {(isArtProofDecisionPending ||
                    isArtProofDecisionDeclined) && (
                    <EditArtProofButton
                      startIcon={<EditIcon />}
                      color="primary"
                      variant="contained"
                      onClick={openCloudinary}
                    />
                  )}
                </ArtproofActions>
              </div>

              {!!artProofNotes && artproofDecision === "declined" && (
                <Notes>
                  <ArtProofTitle>Notes:</ArtProofTitle>
                  <ArtProofStatus artProof={state?.artProof}>
                    {artProofNotes}
                  </ArtProofStatus>
                </Notes>
              )}

              <ArtProofIframe
                artProof={state?.artProof}
                src={state?.artProof?.artProofUrl}
                title="po-secure-url"
              />
            </>
          ) : (
            <div />
            // <div className="upload-artproof">
            //   <UploadPO onClick={openCloudinary}>
            //     <AddCircleIcon />
            //     <h3>Upload Artproof</h3>
            //   </UploadPO>
            // </div>
          )}
        </div>
      </div>
    </OrderInfoContainer>
  );
};

OrderInformation.propTypes = {
  ...PROP_TYPES,
  updateOrder: PropTypes.func,
  sendNotification: PropTypes.func,
  onApprove: PropTypes.func,
};
OrderInformation.defaultProps = {
  ...DEFAULT_STATE,
  updateOrder: (v) => v,
  sendNotification: (v) => v,
  onApprove: () => {},
};

export default OrderInformation;
