import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "components/TextFieldInput";
import RichTextEditor, {
  initiateEditorState,
  getHtml,
} from "components/RichTextEditor";
import Autocomplete from "components/Autocomplete";
import { usePost } from "hooks/usePost";
import { CONFLUENCE_CUSTOMER_REQUEST } from "constants/services";
import openCloudinaryWidget from "utils/cloudinary";
import { DEFAULT_STATE, PROP_TYPES } from "pages/order-request/prop-types";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { CreateOrderRequestBody, Attachment } from "styles/pages/order-request";

const CreateOrderRequestDialog = ({ state, setState, fetchOrderRequests }) => {
  const user = useSelector((s) => s?.user);
  const { mutateAsync } = usePost();

  const [form, setForm] = useState({
    requestId: `TT-${uuidv4()?.substring(0, 6)?.toUpperCase()}`,
    body: initiateEditorState(),
  });

  const handleClose = () => {
    setState({ ...state, showCreateQuoteRequestDialog: false });
  };

  const { requestId, subject, urgency, urgencyInput, body, attachments } = form;

  const disabled =
    !subject?.trim().length || !urgency || !body.getCurrentContent().hasText();

  const onCreate = async () => {
    const payload = {
      requestId,
      subject,
      urgency: urgency?.id,
      attachments,
      message: getHtml(body),
      vendorName: user?.vendorName,
      email: user?.email,
      status: "OPEN",
    };

    const { data } = await mutateAsync({
      url: CONFLUENCE_CUSTOMER_REQUEST,
      data: payload,
      isPut: true,
    });

    if (data?.success) {
      fetchOrderRequests();
    }
  };

  return (
    <Dialog open={state?.showCreateQuoteRequestDialog} onClose={handleClose}>
      <DialogTitle>Create Order Quote Request</DialogTitle>
      <DialogContent>
        <CreateOrderRequestBody>
          <TextField value={requestId} label="Request Id" disabled />
          <TextField
            value={subject}
            handleChange={(e) => setForm({ ...form, subject: e.target.value })}
            label="Briefly, Subject of your request"
          />

          <Autocomplete
            label="Urgency"
            value={urgency}
            setValue={(c) => setForm({ ...form, urgency: c })}
            inputValue={urgencyInput}
            setInputValue={(v) => setForm({ ...form, urgencyInput: v })}
            options={[
              { id: "Critical", label: "Critical" },
              { id: "High", label: "High" },
              { id: "Medium", label: "Medium" },
              { id: "Low", label: "Low" },
            ]}
          />

          <RichTextEditor
            value={body}
            onChange={(v) => setForm({ ...form, body: v })}
            title="Message"
          />

          <Attachment>
            <h1 className="title">
              Attachment <i>(optional)</i>
            </h1>
            <div className="body">
              {attachments?.length > 0 && (
                <div className="attachments">
                  {attachments?.map((a, i) => (
                    <div
                      key={a}
                      aria-hidden="true"
                      className="files"
                      onClick={() => window.open(a)}
                    >
                      <FilePresentIcon fontSize="large" />
                      <h5>File {i + 1}</h5>
                    </div>
                  ))}
                </div>
              )}

              {(!attachments || attachments?.length === 0) && (
                <h3 className="attachment-files">Attachment files</h3>
              )}
              <h3 className="browse">
                <span
                  aria-hidden="true"
                  onClick={() => {
                    openCloudinaryWidget(
                      `ConfluenceApp/OrderQuoteRequests/${requestId}/Attachments`,
                      [],
                      (v) =>
                        setForm({
                          ...form,
                          attachments:
                            !attachments || attachments?.length === 0
                              ? [v?.secure_url]
                              : [...attachments, v?.secure_url],
                        })
                    );
                  }}
                >
                  browse
                </span>
              </h3>
            </div>
          </Attachment>
        </CreateOrderRequestBody>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={disabled} onClick={onCreate}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateOrderRequestDialog.propTypes = PROP_TYPES;

CreateOrderRequestDialog.defaultProps = DEFAULT_STATE;

export default CreateOrderRequestDialog;
