import styled from "styled-components";
import Container from "@mui/material/Container";

export const StyledContainer = styled(Container)``;

export const Wrapper = styled.div``;

export const NoDataFound = styled.h3`
  text-align: center;
  color: #a1a1a1;
`;

export const Filter = styled.div`
  display: flex;
  gap: 20px;
`;

export const OrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
`;

export const QuickFilters = styled.div`
  width: 250px;
`;
