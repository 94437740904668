import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useParams, useNavigate, Link } from "react-router-dom";
import OrderInformation from "pages/order/OrderInformation";
import OrderHistory from "pages/order/OrderHistory";
import Form from "pages/order/Form";
import PageTitle from "components/PageTitle";
import AlertDialog from "components/AlertDialog";
import Button from "components/LoadingButton";
import Snackbar from "components/SnackBar";
import { useFetch } from "hooks/useFetch";
import { usePost } from "hooks/usePost";
import {
  CONFLUENCE_VENDORS,
  CONFLUENCE_ORDERS,
  CONFLUENCE_NOTIFICATIONS,
  PRINT_PROVIDERS,
} from "constants/services";
import { ORDERS } from "constants/routes";
import { cancelled } from "pages/orders/data";
import { StyledContainer, StyledHeader } from "styles/pages/order";

const CreateOrder = () => {
  const navigate = useNavigate();
  const [state, setState] = useState();
  const { orderNumber, vendorName } = useParams();
  const { mutateAsync } = usePost();
  const { mutateAsync: mutateAsyncConfluenceNotification } = usePost();
  const { refetch } = useFetch(CONFLUENCE_VENDORS, CONFLUENCE_VENDORS, {
    enabled: false,
  });

  useEffect(() => setState(), [navigate]);

  const { refetch: refetchOrder } = useFetch(
    CONFLUENCE_ORDERS,
    `${CONFLUENCE_ORDERS}?orderNumber=${orderNumber}&vendorName=${vendorName}`,
    {
      enabled: false,
    }
  );

  const { refetch: refetchPrintProviders } = useFetch(
    PRINT_PROVIDERS,
    PRINT_PROVIDERS,
    {
      enabled: false,
    }
  );

  const fetchPrintProviders = async () => {
    const { data } = await refetchPrintProviders();

    if (data) {
      setState({
        ...state,
        printProviders: data,
      });
    }
  };

  const fetchVendors = async () => {
    const { data } = await refetch();

    if (data) {
      setState({
        ...state,
        vendors: data,
      });
    }
  };

  const initiateState = async (data) => {
    if (data) {
      const p = {
        ...state,
        ...data,
        vendor: { id: data?.vendorName, label: data?.vendorName },
        vendorNameInput: data?.vendorName,
        printProvider: { id: data?.printProvider, label: data?.printProvider },
        printProviderInput: data?.printProvider,
        poNumber: orderNumber,
        assigneeEmails: data?.assigneeEmails?.map((c) => ({
          title: c,
          value: c,
        })),
        clientEmails: data?.clientEmails?.map((c) => ({
          title: c,
          value: c,
        })),
        decorations: data?.decorations?.map((c) => ({
          title: c,
          value: c,
        })),
      };

      for (let i = 0; i < data?.decorations?.length; i += 1) {
        const title = data?.decorations[i];
        const completedDateKey = `${title?.toLowerCase()}CompletedDate`;
        const inProductionDisableKey = `${title?.toLowerCase()}DisableInProductionDate`;
        p[inProductionDisableKey] = !!data[completedDateKey];
      }

      setState(p);
    }
  };

  const fetchOrder = async () => {
    const { data } = await refetchOrder();
    initiateState(data);
  };

  const fetchOrderData = () => {
    if (orderNumber && vendorName) {
      fetchOrder();
    }
  };

  const updateOrder = async (payload) => {
    const { data } = await mutateAsync({
      url: CONFLUENCE_ORDERS,
      isPatch: true,
      data: payload,
    });

    initiateState(data);
  };

  useEffect(() => {
    fetchOrderData();
  }, [orderNumber, vendorName]);

  useEffect(() => {
    if (!state?.vendors) fetchVendors();
  }, [state]);

  useEffect(() => {
    if (!state?.printProviders) fetchPrintProviders();
  }, [state]);

  const getOrderDates = () => {
    const payload = {};

    for (let i = 0; i < state?.decorations?.length; i += 1) {
      const element = state?.decorations[i];
      const inProductionKey = `${element?.title?.toLowerCase()}InProductionDate`;
      const completedKey = `${element?.title?.toLowerCase()}CompletedDate`;
      payload[inProductionKey] = state[inProductionKey];
      payload[completedKey] = state[completedKey];
    }
    return payload;
  };

  return (
    <StyledContainer>
      <Breadcrumbs sx={{ marginBottom: "10px" }}>
        <Link underline="hover" color="inherit" to={ORDERS}>
          Orders
        </Link>
        <Typography color="text.primary">Order</Typography>
      </Breadcrumbs>

      <StyledHeader>
        <PageTitle
          title={
            orderNumber
              ? `Edit Order${state?.status ? ` (${state?.status})` : ""}`
              : "Create Order"
          }
        />
        <div className="actions">
          {/* {!!orderNumber && (
            <Button
              label="Cancel Order"
              variant="contained"
              onClick={() =>
                setState({ ...state, showCancelOrderDialog: true })
              }
            />
          )} */}

          {!!state?.estimateData?.invoice && (
            <Button
              label="Estimate Link"
              variant="contained"
              onClick={() =>
                window.open(state?.estimateData?.qbEstimateData?.Estimate?.Link)
              }
            />
          )}
        </div>
      </StyledHeader>

      <Form
        state={state}
        fetchOrder={fetchOrder}
        setState={setState}
        updateArtfiles={(artfiles) =>
          updateOrder({
            orderNumber: state?.poNumber,
            vendorName: state?.vendor?.label,
            artfiles,
          })
        }
        updateOrderStatus={(status) =>
          updateOrder({
            orderNumber: state?.poNumber,
            vendorName: state?.vendor?.label,
            status,
            requestedShipDate: state?.requestedShipDate,
            requestedInHandDate: state?.requestedInHandDate,
            notifyClients: state?.notifyClients,
            packingSlipUrl: state?.packingSlipUrl,
            // invoiceUrl: state?.invoiceUrl,
            estimationUrl: state?.estimationUrl,
            artfiles: state?.artfiles,
            totalStitchCount: state?.totalStitchCount,
            totalPrintLocations: state?.totalPrintLocations,
            ...getOrderDates(),
          })
        }
        createOrder={async () => {
          const payload = {
            orderNumber: state?.poNumber?.trim(),
            vendorName: state?.vendor?.label?.trim(),
            poSecureUrl: state?.poSecureUrl,
            requestedShipDate: state?.requestedShipDate,
            requestedInHandDate: state?.requestedInHandDate,
            clientEmails: state?.clientEmails?.map((c) => c?.value),
            assigneeEmails: state?.assigneeEmails?.map((c) => c?.value),
            decorations: state?.decorations?.map((c) => c?.value),
            notifyClients: state?.notifyClients,
            printProvider: state?.printProvider?.label,
            artfiles: state?.artfiles,
            totalStitchCount: state?.totalStitchCount,
            totalPrintLocations: state?.totalPrintLocations,
            preApprovedArtproofs: state?.preApprovedArtproofs,
            estimateData: state?.estimateData,
            ...getOrderDates(),
          };
          const { data: response } = orderNumber
            ? await updateOrder(payload)
            : await mutateAsync({
                url: CONFLUENCE_ORDERS,
                data: payload,
                isPut: true,
              });

          if (response) {
            navigate(ORDERS);
          }
        }}
      />

      {orderNumber && (
        <OrderInformation
          state={state}
          setState={setState}
          sendNotification={async (payload) => {
            const { data } = await mutateAsyncConfluenceNotification({
              url: CONFLUENCE_NOTIFICATIONS,
              data: payload,
            });
            initiateState(data);
          }}
          onApprove={(payload) => updateOrder(payload)}
          updateOrder={(artProofUrl) =>
            updateOrder({
              orderNumber,
              vendorName,
              artfiles: state?.artfiles,
              artProof: {
                artProofUrl,
                decision: "pending",
                notes: "",
              },
            })
          }
        />
      )}
      {orderNumber && <OrderHistory state={state} />}
      {!!(state?.apiMessage && state?.apiSeverity) && (
        <Snackbar
          message={state?.apiMessage}
          onClose={() =>
            setState({ ...state, apiMessage: null, apiSeverity: null })
          }
          severity={state?.apiSeverity}
        />
      )}

      {state?.showCancelOrderDialog && (
        <AlertDialog
          open={state?.showCancelOrderDialog}
          title="Cancel Order"
          description="Are you sure, you want to cancel this order?"
          agreeTitle="Yes"
          disagreeTitle="No"
          onAgree={async () => {
            await updateOrder({
              orderNumber: state?.poNumber,
              vendorName: state?.vendor?.label,
              status: cancelled.value,
              notifyClients: state?.notifyClients,
              artfiles: state?.artfiles,
            });
          }}
          onDisagree={() =>
            setState({ ...state, showCancelOrderDialog: false })
          }
        />
      )}
    </StyledContainer>
  );
};

export default CreateOrder;
