import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LogoSm from "assets/images/logo-sm.jpeg";
import QuickBooksLogo from "assets/images/quick-books-logo.png";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";

const QuickBooksConnectDialog = ({ open, connect, cancel, data }) => (
  <Dialog open={open} maxWidth="md" fullWidth>
    <DialogTitle>Quick Books Connection Issue!</DialogTitle>
    <DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
          margin: "20px 0",
        }}
      >
        <div>
          <img src={LogoSm} alt="trutrac" width="75" />
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            TruTrac App
          </div>
        </div>

        <MultipleStopIcon sx={{ fontSize: "50px" }} />

        <div>
          <img src={QuickBooksLogo} alt="quick-books" width="75" />
          <div style={{ fontWeight: "bold", marginTop: "10px" }}>
            QuickBooks
          </div>
        </div>
      </div>
      <DialogContentText sx={{ textAlign: "center", marginTop: "40px" }}>
        Please re-connect with&nbsp;
        <b style={{ fontSize: "20px" }}>{data?.qbCountry} QuickBooks</b>
      </DialogContentText>

      <p style={{ color: "red", textAlign: "center", fontWeight: "bold" }}>
        Note: Please make sure you will connect with {data?.qbCountry}{" "}
        QuickBooks
      </p>
    </DialogContent>
    <DialogActions>
      <Button onClick={cancel}>Cancel</Button>
      <Button onClick={connect}>Connect</Button>
    </DialogActions>
  </Dialog>
);

QuickBooksConnectDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    authUri: PropTypes.string,
    qbCountry: PropTypes.string,
  }),
  cancel: PropTypes.func,
  connect: PropTypes.func,
};

QuickBooksConnectDialog.defaultProps = {
  open: false,
  data: {},
  cancel: () => {},
  connect: () => {},
};

export default QuickBooksConnectDialog;
