import moment from "moment";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { usePost } from "hooks/usePost";
import FormFooter from "pages/order/FormFooter";
import Artfiles from "pages/order/Artfiles";
import Datopicker from "components/Datepicker";
import TextFieldInput from "components/TextFieldInput";
import TagsAutocomplete from "components/TagsAutocomplete";
import Autocomplete from "components/Autocomplete";
import UploadDocuments from "pages/order/UploadDocuments";
import FORM_DATEPICKER_FIELDS from "pages/order/fields-metadata";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
import {
  CONFLUENCE_VENDORS_CLIENTS,
  DECORATIONS,
  CONFLUENCES_USERS,
  CONFLUENCE_SCAN_PURCHASE_ORDER,
} from "constants/services";
import {
  ready,
  inProduction,
  completed,
  // defined
} from "pages/orders/data";
import validateEmail from "utils/email-regex";
import {
  FormWrapper,
  FormRow,
  FormFields,
  Dates,
  OrderDocuments,
  DecorationsInProductionDate,
  Heading,
  Layer,
} from "styles/pages/order";

const Form = ({
  state,
  setState,
  createOrder,
  updateOrderStatus,
  updateArtfiles,
  fetchOrder,
}) => {
  const { mutateAsync: scanPurchaseOrderMutateAsync } = usePost();
  const { orderNumber } = useParams();
  const user = useSelector((store) => store?.user);
  const decision = state?.artProof?.decision;
  // const isOrderDefined = state?.status === defined.value;
  const isOrderReady = state?.status === ready.value;
  const isOrderInProduction = state?.status === inProduction.value;
  const isOrderCompleted = state?.status === completed.value;
  const isArtProofApproved = decision === "approved";
  const isEmbDecorationSelected = !!state?.decorations?.find(
    (d) => d?.title === "EMB"
  );
  const isScreenPrintDecorationSelected = !!state?.decorations?.find(
    (d) => d?.title === "SCREENPRINT"
  );

  const getMinDate = (f) => {
    switch (f) {
      case "requestedShipDate":
        return moment.unix(state?.inProductionDate);

      case "requestedInHandDate":
        return moment.unix(state?.requestedShipDate).add(1, "day");

      default:
        return null;
    }
  };

  const getMaxDate = (f) => {
    switch (f) {
      case "inProductionDate":
        return moment.unix(state?.requestedShipDate);

      default:
        return null;
    }
  };

  const getTextfieldDisableValue = () => {
    if (!orderNumber || isOrderReady) return false;

    return true;
  };

  return (
    <FormWrapper>
      <FormRow>
        <OrderDocuments>
          <Heading>Files</Heading>

          <Dates noMarginTop>
            <Autocomplete
              disabled={!!orderNumber}
              label="Vendor"
              value={state?.vendor}
              setValue={(c) => {
                setState({
                  ...state,
                  vendor: c,
                  clientEmails: [],
                  assigneeEmails: [],
                });
              }}
              inputValue={state?.vendorNameInput}
              setInputValue={(v) =>
                setState({
                  ...state,
                  vendorNameInput: v,
                  clientEmails: [],
                  assigneeEmails: [],
                })
              }
              options={
                state?.vendors?.map((u) => ({
                  ...u,
                  id: u?.name,
                  label: u?.name,
                })) || []
              }
            />
          </Dates>

          <UploadDocuments
            path={`ConfluenceApp/Users/${user?.email}/Orders`}
            // disabled={!!orderNumber || !state?.vendor?.id}
            disabled
            onChange={async (v) => {
              let newState = { ...state, poSecureUrl: v?.secure_url };

              const vendor = state?.vendor?.id;

              if (state?.vendor?.scanning) {
                const { data } = await scanPurchaseOrderMutateAsync({
                  url: CONFLUENCE_SCAN_PURCHASE_ORDER,
                  data: {
                    vendorName: vendor,
                    poSecureUrl: v?.secure_url,
                  },
                });

                const decorations = data?.decorations?.map((d) => ({
                  title: d,
                  value: d,
                }));

                const selectedPrintProviders = state?.printProviders?.find(
                  (p) => p?.printProvider === state?.vendor?.printProvider
                );

                const defaultUsers = selectedPrintProviders?.defaultUsers;

                newState = {
                  ...newState,
                  poNumber: String(data?.poNumber),
                  clientEmails: data?.defaultClients?.map((c) => ({
                    title: c?.clientEmail,
                    value: c?.clientEmail,
                  })),
                  requestedInHandDate: data?.inHandDate,
                  requestedShipDate: data?.shipDate,
                  printProvider: {
                    id: state?.vendor?.printProvider,
                    label: state?.vendor?.printProvider,
                  },
                  decorations: _.uniqBy(decorations, "value"),
                  assigneeEmails: defaultUsers?.map((u) => ({
                    title: u,
                    value: u,
                  })),
                  estimateData: data,
                };
              }

              setState({
                ...state,
                ...newState,
              });
            }}
            url={state?.poSecureUrl}
            label="Upload PO"
            fileName={state?.poSecureUrl?.split("/Orders/")[1]}
            tooltipTitle="Purchase Order"
          />

          {decision === "approved" && (
            <UploadDocuments
              path={`ConfluenceApp/Users/${user?.email}/Orders/${orderNumber}/Estimation`}
              // disabled={!isOrderDefined}
              disabled
              onChange={(v) =>
                setState({ ...state, estimationUrl: v?.secure_url })
              }
              url={state?.estimationUrl}
              label="Estimation"
              fileName={state?.estimationUrl?.split("/Estimation/")[1]}
              tooltipTitle="Estimation"
            />
          )}

          {(isOrderInProduction || isOrderCompleted) && (
            <>
              <UploadDocuments
                path={`ConfluenceApp/Users/${user?.email}/Orders/${orderNumber}/PackingSlips`}
                // disabled={state?.status !== inProduction.value}
                disabled
                onChange={(v) =>
                  setState({ ...state, packingSlipUrl: v?.secure_url })
                }
                url={state?.packingSlipUrl}
                label="Packing Slips"
                fileName={state?.packingSlipUrl?.split("/PackingSlips/")[1]}
                tooltipTitle="Packing Slip"
              />

              {/* <UploadDocuments
                path={`ConfluenceApp/Users/${user?.email}/Orders/${orderNumber}/Invoice`}
                disabled={state?.status !== inProduction.value}
                onChange={(v) =>
                  setState({ ...state, invoiceUrl: v?.secure_url })
                }
                url={state?.invoiceUrl}
                label="Invoice"
                fileName={state?.invoiceUrl?.split("/Invoice/")[1]}
                tooltipTitle="Invoice"
              /> */}
            </>
          )}
        </OrderDocuments>

        <FormFields>
          {(!state?.vendor || !state?.poSecureUrl) && <Layer />}
          <Heading>Information</Heading>

          <Dates>
            <Autocomplete
              disabled={isArtProofApproved}
              label="Print Provider"
              value={state?.printProvider}
              setValue={(c) => {
                const selectedPrintProviders = state?.printProviders?.find(
                  (p) => p?.printProvider === c?.id
                );
                const defaultUsers = selectedPrintProviders?.defaultUsers;
                setState({
                  ...state,
                  printProvider: c,
                  assigneeEmails: defaultUsers?.map((u) => ({
                    title: u,
                    value: u,
                  })),
                });
              }}
              inputValue={state?.printProviderInput}
              setInputValue={(v) =>
                setState({ ...state, printProviderInput: v })
              }
              options={
                state?.printProviders?.map((u) => ({
                  id: u?.printProvider,
                  label: u?.printProvider,
                })) || []
              }
            />
          </Dates>

          {!!state?.vendor?.id && state?.poSecureUrl && (
            <Dates>
              <TagsAutocomplete
                createable
                hardFetchOptions={!!state?.vendor?.id}
                disabled={decision === "approved" || decision === "reviewing"}
                optionApiKey="clientEmail"
                apiEndPoint={`${CONFLUENCE_VENDORS_CLIENTS}/${state?.vendor?.id}`}
                title="Add Client Email"
                subTitle="Add a new Client in this Vendor"
                value={state?.clientEmails}
                setValue={(clientEmails) =>
                  setState({ ...state, clientEmails })
                }
                optionValidation={validateEmail}
                label="Client Email"
              />
            </Dates>
          )}

          {state?.vendor?.id && (
            <Dates>
              <TagsAutocomplete
                disabled={decision === "approved" || decision === "reviewing"}
                optionApiKey="email"
                apiEndPoint={CONFLUENCES_USERS}
                title="Add Assignee Email"
                subTitle="Add a new Assignee in this Vendor"
                value={state?.assigneeEmails}
                setValue={(assigneeEmails) =>
                  setState({ ...state, assigneeEmails })
                }
                optionValidation={validateEmail}
                label="Assignee Email"
              />
            </Dates>
          )}

          <Dates>
            <TagsAutocomplete
              disabled={decision === "approved" || decision === "reviewing"}
              optionApiKey="decoCode"
              apiEndPoint={DECORATIONS}
              value={state?.decorations}
              setValue={(decorations) => setState({ ...state, decorations })}
              label="Decorations"
            />
          </Dates>

          {isEmbDecorationSelected && (
            <Dates>
              <TextFieldInput
                disabled={getTextfieldDisableValue()}
                label="Total Stitch Count"
                type="number"
                value={state?.totalStitchCount}
                handleChange={(e) =>
                  setState({
                    ...state,
                    totalStitchCount: Number(e?.target?.value),
                  })
                }
              />
            </Dates>
          )}

          {isScreenPrintDecorationSelected && (
            <Dates>
              <TextFieldInput
                disabled={getTextfieldDisableValue()}
                label="Total Print Locations"
                type="number"
                value={state?.totalPrintLocations}
                handleChange={(e) =>
                  setState({
                    ...state,
                    totalPrintLocations: Number(e?.target?.value),
                  })
                }
              />
            </Dates>
          )}

          <Dates>
            <TextFieldInput
              label="PO Number"
              disabled={!!orderNumber}
              value={state?.poNumber}
              handleChange={(e) =>
                setState({ ...state, poNumber: e?.target?.value })
              }
            />
          </Dates>

          {!!state?.decorations?.length &&
            (isOrderReady || isOrderInProduction || isOrderCompleted) && (
              <DecorationsInProductionDate decorations={state?.decorations}>
                {state?.decorations?.map((d) => {
                  const key = `${d?.title?.toLowerCase()}InProductionDate`;
                  const inProductionDisableKey = `${d?.title?.toLowerCase()}DisableInProductionDate`;
                  return (
                    <Datopicker
                      key={d?.title}
                      disabled={state[inProductionDisableKey]}
                      minDate={moment()}
                      label={`${d?.title} InProductionDate`}
                      value={state[key] ? moment.unix(state[key]) : null}
                      handleChange={(v) =>
                        setState({ ...state, [key]: v?.unix() || null })
                      }
                    />
                  );
                })}
              </DecorationsInProductionDate>
            )}

          {!!state?.decorations?.length &&
            (isOrderInProduction || isOrderCompleted) && (
              <DecorationsInProductionDate decorations={state?.decorations}>
                {state?.decorations?.map((d) => {
                  const key = `${d?.title?.toLowerCase()}CompletedDate`;
                  return (
                    <Datopicker
                      disabled={isOrderCompleted}
                      key={d?.title}
                      minDate={moment()}
                      label={`${d?.title} CompletedDate`}
                      value={state[key] ? moment.unix(state[key]) : null}
                      handleChange={(v) =>
                        setState({ ...state, [key]: v?.unix() || null })
                      }
                    />
                  );
                })}
              </DecorationsInProductionDate>
            )}

          <Dates>
            {FORM_DATEPICKER_FIELDS?.map((f) =>
              f?.key === "inProductionDate" ? null : (
                <Datopicker
                  disabled={!!orderNumber}
                  key={f?.label}
                  minDate={getMinDate(f?.key)}
                  maxDate={getMaxDate(f?.key)}
                  label={f?.label}
                  value={state[f?.key] ? moment.unix(state[f?.key]) : null}
                  handleChange={(v) =>
                    setState({ ...state, [f?.key]: v?.unix() || null })
                  }
                />
              )
            )}
          </Dates>
        </FormFields>
      </FormRow>

      <Artfiles
        state={state}
        setState={setState}
        updateArtfiles={updateArtfiles}
      />

      <FormFooter
        state={state}
        setState={setState}
        createOrder={createOrder}
        updateOrderStatus={updateOrderStatus}
        fetchOrder={fetchOrder}
      />
    </FormWrapper>
  );
};

Form.propTypes = PROP_TYPES;

Form.defaultProps = DEFAULT_STATE;

export default Form;
