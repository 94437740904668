import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import {
  MessageRow,
  StyledMessage,
  StyledMessageContent,
  MessageTimeStamp,
  MessageDisplayName,
  MessageBody,
} from "styles/components/order-card";

const OrderComments = ({ comments }) =>
  comments?.map((c) => (
    <MessageRow key={c?.timeStamp}>
      <Avatar
        alt={c?.name}
        src={c?.profileImg}
        sx={{ border: "1px solid #ccc", marginRight: "10px" }}
      />

      <MessageBody>
        <MessageDisplayName>{c?.name}</MessageDisplayName>
        <StyledMessage>
          <div>
            <StyledMessageContent>{c?.comment}</StyledMessageContent>
          </div>
          <MessageTimeStamp>
            {moment.unix(c.timeStamp).format("MMM DD,YYYY")}
          </MessageTimeStamp>
        </StyledMessage>
      </MessageBody>
    </MessageRow>
  ));

OrderComments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      name: PropTypes.string,
      profileImg: PropTypes.string,
      user: PropTypes.string,
      timeStamp: PropTypes.number,
    })
  ),
};

OrderComments.defaultProps = {
  comments: [],
};

export default OrderComments;
