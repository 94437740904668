import { useState } from "react";
// import moment from "moment";
import { useParams } from "react-router-dom";
// import Button from "components/LoadingButton";
import Checkbox from "components/Checkbox";
// import { useFetch } from "hooks/useFetch";
import OrderEstimationDialog from "pages/order/OrderEstimationDialog";
import QuickBooksConnectDialog from "pages/order/QuickBooksConnectDialog";
import { PROP_TYPES, DEFAULT_STATE } from "pages/order/prop-types";
// import {
//   QUICK_BOOKS_REFRESH_TOKEN,
//   QUICK_BOOKS_CA_AUTH_REQUEST,
//   QUICK_BOOKS_US_AUTH_REQUEST,
//   QUICK_BOOKS_CUSTOMER,
// } from "constants/services";
import {
  // defined,
  // ready,
  // inProduction,
  // completed,
  shipped,
} from "pages/orders/data";
import { Footer } from "styles/pages/order";

const FormFooter = ({
  state,
  setState,
  // createOrder,
  // updateOrderStatus,
  fetchOrder,
}) => {
  const [quickBooksConnect, setQuickBooksConnect] = useState(false);
  const { orderNumber } = useParams();
  // const decision = state?.artProof?.decision;
  // const isArtProofApproved = decision === "approved";
  const orderStatus = state?.status;
  // const { refetch: quickBooksCustomerRefetch } = useFetch(
  //   `${QUICK_BOOKS_CUSTOMER}?qbCountry=${state?.printProvider?.id}`,
  //   `${QUICK_BOOKS_CUSTOMER}?qbCountry=${state?.printProvider?.id}`,
  //   {
  //     enabled: false,
  //   }
  // );

  // const { refetch: quickBooksRefreshToken } = useFetch(
  //   `${QUICK_BOOKS_REFRESH_TOKEN}?qbCountry=${state?.printProvider?.id}`,
  //   `${QUICK_BOOKS_REFRESH_TOKEN}?qbCountry=${state?.printProvider?.id}`,
  //   { enabled: false }
  // );

  // const { refetch: quickBooksCAAuthToken } = useFetch(
  //   QUICK_BOOKS_CA_AUTH_REQUEST,
  //   QUICK_BOOKS_CA_AUTH_REQUEST,
  //   { enabled: false }
  // );

  // const { refetch: quickBooksUSAuthToken } = useFetch(
  //   QUICK_BOOKS_US_AUTH_REQUEST,
  //   QUICK_BOOKS_US_AUTH_REQUEST,
  //   { enabled: false }
  // );

  // const disabled = !!(
  //   !state?.vendor ||
  //   !state?.poSecureUrl ||
  //   !state?.clientEmails?.length ||
  //   !state?.assigneeEmails?.length ||
  //   !state?.decorations?.length ||
  //   !state?.printProvider ||
  //   !state?.poNumber
  // );

  // const requestedShipDate = moment.unix(state?.requestedShipDate);
  // const requestedInHandDate = moment.unix(state?.requestedInHandDate);

  // const vaidDates =
  //   !!state?.requestedShipDate && !!state?.requestedInHandDate
  //     ? moment(requestedInHandDate).isAfter(requestedShipDate)
  //     : true;

  const isOrderShipped = orderStatus === shipped.value;
  // const isOrderDefined = orderStatus === defined.value;
  // const isOrderReady = orderStatus === ready.value;
  // const isOrderInProduction = orderStatus === inProduction.value;

  // const isScanning = state?.vendors?.find(
  //   (v) => v?.name === state?.vendor?.id
  // )?.scanning;

  return (
    <Footer>
      {!isOrderShipped && (
        <div>
          <Checkbox
            label="Notify Clients"
            checked={state?.notifyClients}
            onChange={(notifyClients) => setState({ ...state, notifyClients })}
          />

          <Checkbox
            label="Pre-Approved Artproofs"
            checked={state?.preApprovedArtproofs}
            onChange={(preApprovedArtproofs) =>
              setState({ ...state, preApprovedArtproofs })
            }
            disabled={!!orderNumber}
          />
        </div>
      )}

      <div>
        {/* {isArtProofApproved && isOrderDefined && (
          <>
            {isScanning && (
              <Button
                label="Create Estimation"
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={async () => {
                  const { data: response } = await quickBooksRefreshToken();

                  if (!response?.success) {
                    const { data: authResponse } = await (state?.printProvider
                      ?.id === "US"
                      ? quickBooksUSAuthToken()
                      : quickBooksCAAuthToken());
                    window.open(authResponse?.authUri);
                  } else {
                    const { data: customers } =
                      await quickBooksCustomerRefetch();

                    if (customers?.authUri) {
                      setQuickBooksConnect(customers);
                      return;
                    }

                    setState({ ...state, orderEstimationDialog: true });
                  }
                }}
              />
            )}

            <Button
              label="Ready"
              disabled={!state?.estimationUrl}
              variant="contained"
              onClick={() => updateOrderStatus(ready.value)}
            />
          </>
        )} 

        {isArtProofApproved && isOrderReady && (
          <div>
            <Button
              label="Update"
              sx={{ marginRight: "10px" }}
              variant="contained"
              disabled={
                !state?.decorations?.find(
                  (d) => state[`${d?.title?.toLowerCase()}InProductionDate`]
                )
              }
              onClick={() => updateOrderStatus(ready.value)}
            />
            <Button
              label="Submit for production"
              variant="contained"
              disabled={
                !!state?.decorations?.find(
                  (d) => !state[`${d?.title?.toLowerCase()}InProductionDate`]
                )
              }
              onClick={() => updateOrderStatus(inProduction.value)}
            />
          </div>
        )}

        {isArtProofApproved && isOrderInProduction && (
          <div>
            <Button
              label="Update"
              sx={{ marginRight: "10px" }}
              variant="contained"
              disabled={
                !!state?.decorations?.find(
                  (d) => !state[`${d?.title?.toLowerCase()}InProductionDate`]
                )
              }
              onClick={() => updateOrderStatus(inProduction.value)}
            />

            <Button
              label="Complete"
              variant="contained"
              disabled={
                !vaidDates ||
                !state?.packingSlipUrl ||
                // !state?.invoiceUrl ||
                !!state?.decorations?.find(
                  (d) => !state[`${d?.title?.toLowerCase()}CompletedDate`]
                )
              }
              onClick={() => updateOrderStatus(completed.value)}
            />
          </div>
        )}

        {!isArtProofApproved && (
          <Button
            label={orderNumber ? "Update" : "Create"}
            variant="contained"
            disabled={disabled}
            onClick={createOrder}
          />
        )} */}
      </div>

      {state?.orderEstimationDialog && (
        <OrderEstimationDialog
          state={state}
          setState={setState}
          fetchOrder={fetchOrder}
        />
      )}

      {quickBooksConnect && (
        <QuickBooksConnectDialog
          open={!!quickBooksConnect}
          data={quickBooksConnect}
          connect={() => {
            setQuickBooksConnect(false);
            window.open(quickBooksConnect.authUri);
          }}
          cancel={() => setQuickBooksConnect(false)}
        />
      )}
    </Footer>
  );
};

FormFooter.propTypes = PROP_TYPES;

FormFooter.defaultProps = DEFAULT_STATE;

export default FormFooter;
