import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Fab from "@mui/material/Fab";
// import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import CryptoJS from "crypto-js";
import Button from "@mui/material/Button";
import { usePost } from "hooks/usePost";
import TextFieldInput from "components/TextFieldInput";
import PasswordInput from "components/PasswordInput";
import LoadingButton from "components/LoadingButton";
import Snackbar from "components/SnackBar";
import { setUser } from "features/user/userSlice";
// import openCloudinaryWidget from "utils/cloudinary";
import { ORDERS } from "constants/routes";
import { CRYPTO_KEY, CONFLUENCES_USER } from "constants/services";
import {
  Col,
  Row,
  Form,
  Actions,
  AvatarWrapper,
  StyledDivider,
} from "styles/pages/settings";

const UpdateUserInformationForm = () => {
  const { mutateAsync } = usePost();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const user = useSelector((state) => state?.user);
  const [state, setState] = useState({
    ...user,
    pin: CryptoJS.AES.decrypt(user?.pin, CRYPTO_KEY).toString(
      CryptoJS.enc.Utf8
    ),
  });

  const updateUserInformation = async () => {
    const payload = {
      fullName: state?.fullName,
      pin: CryptoJS.AES.encrypt(state?.pin, CRYPTO_KEY).toString(),
      profileImg: state?.profileImg,
      vendorName: user?.vendorName,
    };

    const { data } = await mutateAsync({
      url: CONFLUENCES_USER,
      data: payload,
      isPut: true,
    });

    if (data?.success) {
      dispatch(
        setUser({
          ...state,
          pin: payload.pin,
        })
      );
      setMessage(data?.message);
    }
  };

  useEffect(() => {
    if (state?.profileImg !== user?.profileImg) {
      updateUserInformation();
    }
  }, [state?.profileImg]);

  return (
    <>
      <Col>
        <Row flex={1}>
          <Form>
            <TextFieldInput
              label="Email"
              value={state?.email}
              handleChange={() => {}}
              disabled
            />

            <TextFieldInput
              label="Full Name"
              value={state?.fullName}
              handleChange={(e) =>
                setState({ ...state, fullName: e?.target?.value })
              }
            />

            <PasswordInput
              label="PIN"
              value={state?.pin}
              setValue={(v) => setState({ ...state, pin: v })}
            />

            <TextFieldInput
              label="Role"
              value={state?.roleName}
              handleChange={() => {}}
              disabled
            />

            <Actions>
              <Button
                size="large"
                variant="outlined"
                onClick={() => navigate(ORDERS)}
              >
                Cancel
              </Button>

              <LoadingButton
                size="large"
                onClick={updateUserInformation}
                disabled={!state?.pin?.trim() || !state?.fullName?.trim()}
                label="Save"
                variant="contained"
              />
            </Actions>
          </Form>
        </Row>

        <Row flex={0.3}>
          <AvatarWrapper>
            <Avatar
              alt="profile"
              src={state?.profileImg}
              sx={{ width: 200, height: 200 }}
            />
            {/* <Fab
              color="primary"
              onClick={() => {
                openCloudinaryWidget(
                  `ConfluenceApp/Users/${state?.email}/ProfileImage`,
                  [],
                  (v) => setState({ ...state, profileImg: v?.secure_url })
                );
              }}
            >
              <EditIcon />
            </Fab> */}
          </AvatarWrapper>

          <StyledDivider />
          <div>
            <b>{state?.fullName}</b>
          </div>
          <div>{state?.email}</div>
        </Row>
      </Col>

      <Snackbar
        message={message}
        onClose={() => setMessage(null)}
        severity="success"
      />
    </>
  );
};

export default UpdateUserInformationForm;
