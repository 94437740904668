import { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
// import Badge from "@mui/material/Badge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import OrderMarkShippedDialog from "components/OrderCard/OrderMarkShippedDialog";
import AttentionMessageDialog from "components/OrderCard/AttentionMessageDialog";
import OrderCommentsDialog from "components/OrderCard/OrderCommentsDialog";
import { usePost } from "hooks/usePost";
import {
  ORDER_STATUS,
  completed,
  inProduction,
  shipped,
} from "pages/orders/data";
import { getArtProofStatusTitle } from "components/OrderCard/utils";
import { CONFLUENCE_ORDERS } from "constants/services";
import {
  Container,
  Body,
  Wrapper,
  Status,
  Header,
  PONumber,
  VendorName,
  UploadDate,
  InProductionDate,
  ShippedDate,
  ArtProofWrapper,
  VendorLogo,
  // MarkAsShipped,
  // StyledCommentIcon,
  DecorationChips,
  DecorationChip,
  // Comment,
  Flag,
  // StyledCampaignIcon,
} from "styles/components/order-card";

const OrderCard = ({ data, handleClick, refresh }) => {
  const [open, setOpen] = useState(false);
  const [attentionMessageDialogVisibility, setAttention] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [commentDialog, toggleCommentDialog] = useState(false);
  const { mutateAsync } = usePost();
  // const isOrderCompleted = data?.status === completed?.value;
  const isOrderInProduction = data?.status === inProduction?.value;

  const updateOrder = async (payload) => {
    await mutateAsync({
      url: CONFLUENCE_ORDERS,
      isPatch: true,
      data: payload,
    });
    refresh();
  };

  // const openCommentDialog = (e) => {
  //   e.stopPropagation();
  //   setSelectedOrder(data);
  //   toggleCommentDialog(!commentDialog);
  // };

  return (
    <>
      <Container>
        <Body
          status={data?.status}
          artproof={!!data?.artProof}
          onClick={handleClick}
        >
          {/* <Tooltip title="Attention Message" arrow>
            <StyledCampaignIcon
              onClick={(e) => {
                e.stopPropagation();
                setSelectedOrder(data);
                setAttention(true);
              }}
            />
          </Tooltip> */}

          <VendorLogo
            src={data?.vendorLogo}
            onClick={(e) => e.stopPropagation()}
          >
            <Flag src={data?.flagUrl} alt="print-provider" />
            {/* <Comment>
              <Tooltip title="Comments" arrow>
                <Badge
                  badgeContent={data?.orderComments?.length}
                  color="error"
                  onClick={openCommentDialog}
                >
                  <StyledCommentIcon onClick={openCommentDialog} />
                </Badge>
              </Tooltip>
            </Comment> */}
          </VendorLogo>
          <Wrapper>
            <Header>
              <div>
                <PONumber>{data?.orderNumber}</PONumber>
                <VendorName>{data?.vendorName}</VendorName>
              </div>
              <Tooltip title={data?.orderEvents[0]?.name} arrow>
                <Avatar
                  src={data?.orderEvents[0]?.profileImg}
                  sx={{
                    bgcolor: "#a1a1a1",
                    width: 32,
                    height: 32,
                    fontSize: 12,
                    border: "1px solid #a1a1a1",
                  }}
                />
              </Tooltip>
            </Header>
            <div>
              <Status status={data?.status}>{data?.status}</Status>
            </div>
            <UploadDate>
              Created Date :&nbsp;
              {moment.unix(data?.createdDate).format("MMM DD,YYYY")}
            </UploadDate>
            {(data?.status === inProduction.value ||
              data?.status === completed.value ||
              data?.status === shipped.value) &&
              data?.inProductionDate && (
                <InProductionDate>
                  Production Date :&nbsp;
                  {moment.unix(data?.inProductionDate).format("MMM DD,YYYY")}
                </InProductionDate>
              )}

            {data?.status === shipped.value && !!data?.trackingDetails?.length && (
              <ShippedDate>
                Shipped Date :&nbsp;
                {moment
                  .unix(_.last(data?.trackingDetails)?.shippedDate)
                  .format("MMM DD,YYYY")}
              </ShippedDate>
            )}

            <DecorationChips>
              {data?.decorations?.map((d) => {
                const inProductionDateKey = `${d?.toLowerCase()}InProductionDate`;
                const completedDateKey = `${d?.toLowerCase()}CompletedDate`;
                const inProductionDate = data[inProductionDateKey];
                const completedDate = data[completedDateKey];
                return (
                  <Tooltip
                    title={`${
                      inProductionDate || completedDate
                        ? moment
                            .unix(completedDate || inProductionDate)
                            .format("MMM DD, YYYY")
                        : `Not Set`
                    }${
                      d === "SCREENPRINT" && data?.totalPrintLocations
                        ? ` , Print Locations : ${data?.totalPrintLocations}`
                        : ""
                    }${
                      d === "EMB" && data?.totalStitchCount
                        ? `, Stitch Counts : ${data?.totalStitchCount}`
                        : ""
                    }`}
                    key={d}
                    arrow
                  >
                    <DecorationChip
                      inProductionDate={inProductionDate}
                      completedDate={completedDate}
                    >
                      {!!completedDate && (
                        <CheckCircleIcon sx={{ fontSize: "12px" }} />
                      )}

                      {!completedDate && !!inProductionDate && (
                        <CachedIcon sx={{ fontSize: "12px" }} />
                      )}

                      {!completedDate && !inProductionDate && (
                        <InfoIcon sx={{ fontSize: "12px" }} />
                      )}
                      {d}
                    </DecorationChip>
                  </Tooltip>
                );
              })}
            </DecorationChips>

            {!!data?.trackingDetails?.length && (
              <UploadDate>
                Tracking :&nbsp;
                {data?.trackingDetails
                  ?.map((t) => t?.trackingNumber)
                  ?.join(", ")}
              </UploadDate>
            )}
          </Wrapper>

          <ArtProofWrapper artProof={data?.artProof}>
            {data?.status !== inProduction.value && (
              <ErrorOutlineIcon sx={{ fontSize: "16px" }} />
            )}
            {getArtProofStatusTitle(data?.artProof)}
          </ArtProofWrapper>

          {/* {(isOrderCompleted || isOrderInProduction) && (
            <MarkAsShipped
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            >
              {isOrderInProduction ? "Mark Partially Shipped" : "Mark Shipped"}
            </MarkAsShipped>
          )} */}
        </Body>
      </Container>

      {attentionMessageDialogVisibility && (
        <AttentionMessageDialog
          open={attentionMessageDialogVisibility}
          setOpen={() => {
            setAttention(false);
            setSelectedOrder(null);
          }}
          order={selectedOrder}
          refresh={refresh}
        />
      )}
      {open && (
        <OrderMarkShippedDialog
          open={open}
          enablePartialShippingMessage={isOrderInProduction}
          setOpen={setOpen}
          onSubmit={(payload) => {
            const p = {
              ...payload,
              notifyClients: true,
              status: shipped.value,
              orderNumber: data?.orderNumber,
              vendorName: data?.vendorName,
            };
            setOpen(false);
            updateOrder(p);
          }}
        />
      )}

      {commentDialog && (
        <OrderCommentsDialog
          open={commentDialog}
          setOpen={() => toggleCommentDialog(!commentDialog)}
          order={selectedOrder}
          refresh={refresh}
        />
      )}
    </>
  );
};

OrderCard.propTypes = {
  data: PropTypes.shape({
    totalStitchCount: PropTypes.number,
    totalPrintLocations: PropTypes.number,
    flagUrl: PropTypes.string,
    vendorLogo: PropTypes.string,
    decorations: PropTypes.arrayOf(PropTypes.string),
    trackingDetails: PropTypes.arrayOf(
      PropTypes.shape({
        trackingNumber: PropTypes.string,
        shippedDate: PropTypes.string,
      })
    ),
    orderComments: PropTypes.arrayOf(
      PropTypes.shape({ user: PropTypes.string })
    ),
    orderNumber: PropTypes.string,
    vendorName: PropTypes.string,
    createdDate: PropTypes.number,
    inProductionDate: PropTypes.number,
    shippedDate: PropTypes.number,
    status: PropTypes.oneOf(ORDER_STATUS),
    artProof: PropTypes.shape({
      decision: PropTypes.string,
    }),
    orderEvents: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        profileImg: PropTypes.string,
      })
    ),
  }),
  handleClick: PropTypes.func,
  refresh: PropTypes.func,
};

OrderCard.defaultProps = {
  data: {},
  handleClick: () => {},
  refresh: () => {},
};

export default OrderCard;
