import SettingsIcon from "@mui/icons-material/Settings";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ListAltIcon from "@mui/icons-material/ListAlt";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  SETTINGS,
  ORDERS,
  ORDER_REQUEST,
  // ORDER,
  // CALENDAR
} from "constants/routes";

const MENUS = [
  // {
  //   title: "Create Order",
  //   Icon: AddCircleIcon,
  //   link: ORDER,
  // },
  {
    title: "Orders",
    Icon: LocalMallIcon,
    link: ORDERS,
  },
  {
    title: "Order Request",
    Icon: ListAltIcon,
    link: ORDER_REQUEST,
  },
  // {
  //   title: "Calendar",
  //   Icon: CalendarMonthIcon,
  //   link: CALENDAR,
  // },
  {
    title: "Settings",
    Icon: SettingsIcon,
    link: SETTINGS,
  },
];

export default MENUS;
