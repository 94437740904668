import styled from "styled-components";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Button from "components/LoadingButton";
import { getBackgroundColorByArtProof } from "components/OrderCard/utils";

export const StyledContainer = styled(Container)``;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const StyledHeader = styled.div`
  position: relative;
  .actions {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 10px;

    button {
      padding: 4px 10px;
      font-size: 11px;
    }
    button:nth-child(1) {
      background: red;
      :hover {
        background: red;
      }
    }
    button:nth-child(2) {
      background: green;
      :hover {
        background: green;
      }
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  padding-top: 10px;
  flex: 1;
  gap: 20px;

  .MuiAutocomplete-root {
    width: 100%;
  }
`;

export const Dates = styled.div`
  display: flex;
  gap: 20px;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? 0 : "20px")};
`;

export const FormFields = styled.div`
  flex: 1;
  position: relative;
`;

export const UploadPOContainer = styled.div`
  flex: 1;
  position: relative;

  svg.download {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
  }
`;

export const UploadPO = styled.div`
  width: 200px;
  border: 1px dashed #a1a1a1;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: ${(props) => (props?.disabled ? "no-drop" : "pointer")};
  flex-direction: column;
  opacity: ${(props) => (props?.disabled ? 0.3 : 1)};
  flex: 1;

  svg.pdf {
    font-size: 40px;
    color: #a1a1a1;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    white-space: nowrap;
    color: black;
    text-align: center;
  }
`;

export const OrderInfoContainer = styled.div`
  margin-top: 10px;
  .row {
    display: flex;
    gap: 20px;
  }
  .col {
    flex: 1;
    iframe {
      width: 100%;
      min-height: 600px;
    }

    .upload-artproof {
      display: flex;
      justify-content: center;
      height: 140px;
      cursor: pointer;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .MuiStepContent-root,
  .MuiStepConnector-root {
    margin-left: 19px;

    .MuiTypography-root {
      color: #a1a1a1;
      font-size: 12px;
    }
  }
`;

export const ArtProofStatus = styled.span`
  text-transform: capitalize;
  padding-left: 4px;
  color: ${({ artProof }) => getBackgroundColorByArtProof(artProof)}; ;
`;

export const ArtProofTitle = styled.span`
  font-size: 12px;
  color: #ccc;
`;

export const StepperLabel = styled.div`
  display: flex;
  justify-content: space-between;

  .MuiTypography-root {
    color: #a1a1a1;
    font-size: 12px;
  }
`;

export const ArtproofActions = styled.div`
  justify-content: center;
  display: flex;
  gap: 10px;
  height: 40px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const EditArtProofButton = styled(Button)`
  &.MuiButtonBase-root {
    min-width: 40px;
  }

  .MuiButton-startIcon {
    margin-right: 0;
    margin-left: 0;
  }
`;

export const ArtProofIframe = styled.iframe`
  border: ${({ artProof }) =>
    `3px solid ${getBackgroundColorByArtProof(artProof)}`};
`;

export const Notes = styled.h3`
  margin-top: 0;
`;

export const OrderDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding-right: 20px;
`;

const getFRs = (arr) => {
  let value = "";

  for (let i = 0; i < arr?.length; i += 1) {
    value += "1fr ";
  }
  return value;
};

export const DecorationsInProductionDate = styled.div`
  display: grid;
  grid-template-columns: ${({ decorations }) => getFRs(decorations)};
  gap: 10px;
  margin-top: 20px;
`;

export const StyledArtfiles = styled(Paper)`
  margin-top: 20px;
  padding: 14px;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    align-items: center;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    svg {
      cursor: pointer;
    }
  }
`;

export const NoArtfileFound = styled(Alert)`
  margin-top: 10px;
`;

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 26px;
  margin-bottom: 10px;
  gap: 20px;

  .company-address-line-one,
  .company-address,
  .company-email {
    font-size: 14px;
  }
`;

export const StyledSubHeader1 = styled.div`
  display: grid;
  justify-content: space-between;
  line-height: 26px;
  margin-bottom: 10px;
  gap: 20px;
  grid-template-columns: 1fr 2fr 1fr;
  grid-auto-flow: column;

  .company-address-line-one,
  .company-address,
  .company-email {
    font-size: 14px;
  }
`;

export const OrderEstimationDialogBody = styled(Container)`
  margin: 15px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "20px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "10px" : 0)};
`;

export const InvoiceDetails = styled.div``;

export const Address = styled.div`
  margin-top: 10px;
`;

export const ShipTo = styled.div``;

export const Row = styled.div`
  margin-bottom: 8px;
  display: flex;
  gap: 20px;
`;

export const LineItems = styled.div`
  .MuiOutlinedInput-root {
    background-color: white;
  }
`;

export const LineItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  margin-bottom: 14px;
  margin-top: 20px;
  align-items: center;

  h2 {
    margin: 0;
  }
`;

export const LineItemFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const Heading = styled.h3`
  margin: 0;
`;

export const Layer = styled.div`
  position: absolute;
  cursor: no-drop;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
`;

export const Total = styled.div`
  display: flex;
  gap: 20px;
  font-weight: bold;
  margin: 50px 0 10px;
  .thanks {
    flex: 1;
  }
  .table {
    .row {
      display: flex;
      gap: 20px;
      padding-bottom: 10px;
      .col {
        flex: 1;
      }
    }
  }
`;

export const LineItem = styled.div`
  margin: 20px 0;
  display: flex;
  width: 500px;
  gap: 20px;
`;
