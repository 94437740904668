import PropTypes from "prop-types";
import Button from "components/LoadingButton";
// import { useNavigate } from "react-router-dom";
// import { ORDER } from "constants/routes";
import {
  Container,
  StyledTitle,
  // StyledAddCircleIcon,
  // Icons,
} from "styles/components/page-title";

const PageTitle = ({
  title,
  onCreateOrderQuoteRequest,
  // createOrder
}) => {
  // const navigate = useNavigate();

  return (
    <Container>
      <StyledTitle variant="h5">{title}</StyledTitle>

      {!!onCreateOrderQuoteRequest && (
        <Button
          label="Create Request"
          variant="contained"
          onClick={onCreateOrderQuoteRequest}
        />
      )}

      {/* <Icons>
        {createOrder && <StyledAddCircleIcon onClick={() => navigate(ORDER)} />}
      </Icons> */}
    </Container>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  onCreateOrderQuoteRequest: PropTypes.func,
  // createOrder: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: "",
  onCreateOrderQuoteRequest: false,
  // createOrder: false,
};

export default PageTitle;
