import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "styles/components/autocomplete";

const CustomAutocomplete = ({
  options,
  value,
  setValue,
  inputValue,
  setInputValue,
  label,
  disabled,
}) => (
  <Container>
    <Autocomplete
      disabled={disabled}
      value={value}
      size="small"
      onChange={(event, newValue) => setValue(newValue)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      disablePortal
      options={options}
      isOptionEqualToValue={(option, v) => option.id === v.id}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  </Container>
);

CustomAutocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  inputValue: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomAutocomplete.defaultProps = {
  value: null,
  inputValue: "",
  label: "",
  disabled: false,
};

export default CustomAutocomplete;
